import React from "react";

import "./styles.sass"
const TopBar = () => (
    <header>
        <img src="/svg/MoncatLogo.svg" alt="Moncat Logotype" />
        <img className="logo" src="/svg/TshirtIcon.svg" alt="Moncat Tshirt Icon" />
    </header>
)

export default TopBar