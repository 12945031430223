import React from "react";

import "./styles.sass"

const BottomBar = () => (
    <footer>
        <p><a href="mailto:hello@moncat.pt" target="_blank" rel="noreferrer">hello@moncat.pt</a></p>
        <p>Website Coming Soon</p>
    </footer>
)

export default BottomBar