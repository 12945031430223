import * as React from "react"
import TopBar from "../components/TopBar"
import BottomBar from "../components/BottomBar"

import SEO from "../components/seo"

import "./styles.sass"
// markup
const IndexPage = () => {
  return (
    <main>
      <SEO />
      <TopBar />
      <div className="content">
        High quality, long-lasting tshirts produced in Portugal with 100% organic cotton.
      </div>
      <BottomBar />
    </main>
  )
}

export default IndexPage
